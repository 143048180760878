#insight-widget {
    margin: 0 auto;
    max-width: 65ch;

    border: 1px solid lightgray;
    box-shadow: 5px 5px 0 lightgray;
}

#insight-widget__iframe-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 62.5%;
}

#insight-widget__iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}