#zeynep-intro {
    max-width: 80ch;
    margin: $spacer--wanton auto;

    @media (min-width: $mobile-breakpoint) {
        display: flex;
    }
}

#zeynep-intro__portrait {
    margin: 0 auto;
}

#zeynep-intro__sidebar {
    @media (min-width: $mobile-breakpoint) {
        width: 200px;
        margin-right: $spacer; 
    }
}

#zeynep-intro__social-links {
    margin: $spacer 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

    .zeynep-intro__social-link {
        padding: $spacer--tighter;
        margin: 0 1px;
        border-radius: 4px;
        transition: $transition--background;

        &:hover {
            background-color: $color--highlight;
        }
    }

#zeynep-intro__text {
    font-size: $font-size--large;
    font-weight: $font-weight--light;
}
