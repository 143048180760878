@import 'variables';
@import 'normalize';

@import 'widgets/intro';
@import 'widgets/insight';
@import 'widgets/twtg';
@import 'widgets/articles';

/* Generic Elements */

body {
    font-size: $font-size--normal;
    line-height: 1.5;
    font-family: 'Barlow', sans-serif;
    font-weight: $font-weight--regular;
    padding: $spacer;

    color: black;
    background-color: white;
}

b {
    font-weight: $font-weight--semibold;
}

a {
    color: inherit;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    &:visited {
        color: gray;
    }
}

img {
    display: block;
}

mark,
.highlight {
    background-color: $color--highlight;
    padding: 2px;
}

/* Decorations */

.color--brand {
    color: $color--brand;
}

.title {
    font-style: italic;
}

.quote--outdent {
    text-indent: -0.3ch;
}

/* Elements */

header {
    text-align: right;

    font-weight: $font-weight--semibold;
    font-style: italic;

    > nav {
        padding: $spacer;

        > a {
            text-decoration: none;
            color: inherit;
            padding: $spacer--tight;
            border-radius: 10px 15px 8px 15px;

            transition: $transition--background;
            &:hover {
                background-color: $color--highlight;
            }

            &:visited {
                color: inherit;
            }
        }
    }
}

nav {
    > a {
        margin: 0 $spacer--tight;
    }
}

footer {
    font-size: $font-size--small;
    text-align: center;

    > nav {
        margin: $spacer 0;
    }
}

#footer__follow-please {
    font-weight: $font-weight--semibold;
}

/* Dividers */

.divider {
    margin: $spacer--wanton 0;
    height: 1px;
    // filter: blur(1px);
    // background-color: black;
}

/* Special Things */

#coming-soon {
    text-align: center;
    transform: rotate(-2deg);
}