#articles-widget {
    max-width: 110ch;
    margin: 0 auto;

    h2 {
        text-align: center;
        font-style: italic;
    }
}

#articles-widget__articles {
    @media (min-width: $mobile-breakpoint) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.articles-widget__article {
    text-decoration: none;

    display: block;
    margin-top: $spacer--wanton;
    margin-bottom: $spacer--wanton;

    &:hover {
        .articles-widget__article__title {
            text-decoration: underline;
        }
    }

    &:visited {
        color: inherit;
    }

    @media (min-width: $mobile-breakpoint) {
        flex-basis: 47%;
        margin-top: $spacer--roomy;
        margin-bottom: $spacer--roomy;
    }
}

.articles-widget__article__title {
    margin: 0;
}

.articles-widget__article__image {
    width: 100%;
    height: auto;
    margin-top: $spacer--tight;
    margin-bottom: $spacer--tight;
}

.articles-widget__article__date {
    font-style: italic;
}

.articles-widget__article__summary {
    margin: 0;
}

