/* Variables */

$mobile-breakpoint: 500px;

$transition--background: background-color 0.25s ease;

$color--brand: #118080;
$color--highlight: #FFF2C2;

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--semibold: 600;

$font-size--large: 1.1rem;
$font-size--normal: 1rem;
$font-size--small: 0.9rem;
$font-size--tiny: 0.8rem;

$spacer: 1rem;
$spacer--tighter: 0.25rem;
$spacer--tight: 0.5rem;
$spacer--roomy: 1.5rem;
$spacer--wanton: 1.75rem;

@media (min-width: $mobile-breakpoint) {
    $font-size--large: 1.25rem;
    $font-size--normal: 1rem;
    $font-size--small: 0.9rem;
    $font-size--tiny: 0.8rem;

    $spacer: 1rem;
    $spacer--tighter: 0.25rem;
    $spacer--tight: 0.5rem;
    $spacer--roomy: 2rem;
    $spacer--wanton: 3rem;
}
